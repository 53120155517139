import React from "react"
import { graphql, useStaticQuery } from 'gatsby'
import ProjectTemplate from '../../../../templates/project-template.js';
import { removeDataAttributes } from "../../../../comQueryStructure.js";

function DubaiProjectTemplatePage(props){

  const data = useStaticQuery(graphql`
  query DubaiProjectQuery {
    glstrapi {
      articles(
        filters: {all_menus: {Primary_URL: {eq: "new-projects-in-dubai"}, Publish: {eq: true}}}
      ) {
        data {
          id
          attributes {
            Alias
            Content_Heading_Text
            Content
            Layout
            Meta_Title
            Meta_Description
            Page_CSS_Class
            Custom_CSS_Class
            Pagename
            Publish
            Select_Popular_Search
            imagetransforms
            ggfx_results {
              data {
                attributes {
                  src_cftle
                  transforms 
                }
              }
            }
            Header_Banner_Image {
              data {
                id
                attributes {
                  url
                  alternativeText
                }
              }
            }
            Header_Banner_Video
            Play_Button
            popular_search {
              data {
                attributes {
                  Module_Name
                  ModuleType {
                    ... on GLSTRAPI_ComponentModContentBlock {
                      id
                      Title
                      Content
                    }
                  }
                }
              }
            }
            Add_Modules {

              __typename
    
              ... on GLSTRAPI_ComponentModShowInsights {
                id
                Show_Insights
                Filter_by_Tag
              }
    
              ... on GLSTRAPI_ComponentModContentBlock {
                id
                Title
                Content
              }
    
              ... on GLSTRAPI_ComponentModCollections {
                id
                Select_Collection
              }
            }
          }
        }
      }
    }
  }`)

  const projData = removeDataAttributes(data)

  return (
    <ProjectTemplate 
      location={props.location}
      GQLPage = {projData.glstrapi?.articles[0]}
      GQLModules = {projData.glstrapi?.articles[0]?.Add_Modules}
    />
  )
}

export default DubaiProjectTemplatePage